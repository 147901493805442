@font-face {
    font-family: Shentox;
    font-display: swap;
    src: url('../assets/fonts/Shentox-SemiBold.woff2') format('woff2'), url('../assets/fonts/Shentox-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: Shentox;
    font-display: swap;
    src: url('../assets/fonts/Shentox-Medium.woff2') format('woff2'), url('../assets/fonts/Shentox-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Shentox;
    font-display: swap;
    src: url('../assets/fonts/Shentox-Regular.woff2') format('woff2'), url('../assets/fonts/Shentox-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Shentox;
    font-display: swap;
    src: url('../assets/fonts/Shentox-Light.woff2') format('woff2'), url('../assets/fonts/Shentox-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: Rogan;
    font-display: swap;
    src: url('../assets/fonts/Rogan-SemiBold.woff2') format('woff2'), url('../assets/fonts/Rogan-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: Rogan;
    font-display: swap;
    src: url('../assets/fonts/Rogan-Light.woff2') format('woff2'), url('../assets/fonts/Rogan-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: Rogan;
    font-display: swap;
    src: url('../assets/fonts/Rogan-Medium.woff2') format('woff2'), url('../assets/fonts/Rogan-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Rogan;
    font-display: swap;
    src: url('../assets/fonts/Rogan-Regular.woff2') format('woff2'), url('../assets/fonts/Rogan-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Electrolize';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/Electrolize.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}